import styled from "@emotion/styled"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import camelCase from "lodash/camelCase"
import get from "lodash/get"
import React, { useState } from "react"
import Layout from "../components/Layout"
import NoRecord from "../components/NoRecord"
import Pagination from "../components/Pagination"
import SEO from "../components/Seo"
import TabHeader from "../components/TabHeader"

const FinancialReportsPage = ({ pageContext, data }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { langKey } = pageContext
  const title = "Financial Reports"
  const _langKey = camelCase(langKey)
  const perPage = 30

  const indexOfLastItem = currentPage * perPage
  const indexOfFirstItem = indexOfLastItem - perPage
  const isFirst = currentPage === 1
  const isLast = currentPage === Math.ceil(data.records.edges.length / perPage)
  const currentItems = data.records.edges.slice(
    indexOfFirstItem,
    indexOfLastItem
  )

  return (
    <Layout langKey={langKey}>
      <SEO title={title} />

      <TabHeader title={title} />
      <Container>
        {currentItems.length > 0 ? (
          currentItems.map(({ node }, i) =>
            node.name[_langKey] ? (
              <Card
                key={i}
                target="_blank"
                href={get(node, `file[${_langKey}].asset.url`)}
              >
                <Image {...node?.thumbnail} alt={i} />

                <FileName>{get(node, `name[${_langKey}]`)}</FileName>
              </Card>
            ) : null
          )
        ) : (
          <NoRecord langKey={langKey} />
        )}
      </Container>
      <Pagination
        {...{
          langKey,
          perPage,
          isFirst,
          isLast,
          currentPage,
          setCurrentPage,
          noRecord: data.records.edges.length === 0,
          totalCount: data.records.totalCount,
        }}
      />
    </Layout>
  )
}

export default FinancialReportsPage

export const query = graphql`
  {
    records: allSanityRecord(
      filter: { category: { name: { en: { eq: "Financial Reports" } } } }
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          name {
            zhHant
            en
          }
          file {
            en {
              asset {
                url
              }
            }
            zhHant {
              asset {
                url
              }
            }
          }
          thumbnail {
            ...ImageWithPreview
          }
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
`

const Card = styled.a`
  width: calc(100% / 3);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 200px;
  margin-bottom: 6rem;
`

const Image = styled(SanityImage)`
  margin: 1.2rem 0;
  max-height: 100%;
  width: auto;
  // -webkit-box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.5);
  // box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.5);
  box-shadow: -5px 5px 20px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 5px 30px -9px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -5px 5px 10px -4px rgba(0, 0, 0, 0.5);
`
const FileName = styled.span`
  font-size: 16px;
  color: #666;
  text-align: center;
  padding: 0 1rem;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
`
